import React, { useMemo } from "react";
export const AppContext = React.createContext();

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
}

export function useOrganisations(){
  const context = useAppContext()
  return context.organisationsList
}

export function useIsAuthenticated() {
  const context = useAppContext();
  return context.user;
}

export function useCurrentProperty() {
  const context = useAppContext();
  return useMemo(() => {
    return {currentProperty:context.currentProperty, changeProperty:context.changeProperty, properties: context.properties}
  },[context]);
}

export function useOrganisation() {
  const context = useAppContext();
  return context.user?.organisations?.[0]
}
