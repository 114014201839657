import { useState } from "react";
import { apiClient } from ".";
import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { LoadingRef } from "../../app/AppProvider";

const emptyfun = () => { };

export const useApiRequest = (
  uri,
  { showLoader = false, onCompleted = emptyfun, onError = emptyfun, showSuccess = 'success', showAlert = false, showErrorAlert = true }
) => {
  const toast = useToast()
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fetched, setFetched] = useState(false);

  const request = useCallback(
    async ({ method = "POST", ...config } = { method: "POST" }, extraData) => {
      setLoading(true);
      showLoader && LoadingRef.current?.show()
      let apiConfig = { url: uri, method, ...config };

      const response = await apiClient.any(apiConfig);
      
      setLoading(false);
      LoadingRef.current?.hide()
      setFetched(true);

      if (response.ok) {
        onCompleted(response.data, response, extraData);
        setData(response.data);
        if(showAlert){
          toast({
            status: "success",
            title: showAlert === true ? 'Success' : showAlert,
            position: "top",
          })
        }
      } else {
        onError(response.problem, response.data, response, extraData);
        setError(response.problem);

        toast({
          status: "warning",
          title: !response.data ? 'Failed' : response.data.message,
          position: "top",
          duration:1000,
        })
      }
    },
    [onCompleted, onError, showAlert, showLoader, toast, uri]
  );

  const reset = () => {
    setData();
    setError(false);
    setLoading(false);
    setFetched(false);
  };
  const refetch = () => {
    reset();
    request();
  };

  return { request, fetched, loading, data, error, refetch, reset };
};
