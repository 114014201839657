
import React, { useEffect, useReducer } from 'react'
import { Box, Button, Center, FormControl, FormLabel, HStack, Input, Radio, RadioGroup, Select, Stack, Textarea, VStack } from '@chakra-ui/react'
import { find, map, orderBy } from 'lodash'
import { DropdownList } from 'react-widgets/cjs'
import { AiOutlineDoubleRight } from 'react-icons/ai';
import { URIS } from '../../services/api'
import { apis } from '../../services/api/apis'
import { FormReducer } from '../../utils/FormReducer'
import { useGetData } from '../../utils/useGetData'
import { useApiRequest } from '../../services/api/useApiRequest'
import { useUserData } from '../../services/api/useUserData'
import { useCompanyData } from '../../services/api/useCompanyData';

export const BasicDetails = ({ nextStep, setData, currentProperty, defaultData, closeModal, firstProperty }) => {
    const [propertyData, changeData] = useReducer(FormReducer, { gender: 'CO-ED' })
    const { organisation } = useUserData(true)
    const { data: allStates } = useGetData(URIS.STATES, () => apis.getStatesApi(), true)
    const { data: allCompanies, loading: loadingCompanies } = useCompanyData(true)

    const { request: addRequest, loading: addLoading } = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted: (d) => {
            nextStep(1)

            if (!defaultData)
                setData(d?.property)
            else
                closeModal()
        },
        onError: () => { },
        showAlert: true
    })

    useEffect(() => {
        if (defaultData?.id) {
            const { name, streetAddress, town, state, district, pincode, gender } = defaultData
            changeData({ type: 'reset', value: { name, streetAddress, town, state, district, pincode, gender } })
        }
    }, [defaultData])

    const handleChange = (type, value) => {
        if (type === 'state')
            changeData({ type: 'merge', value: { state: value, district: null } })
        else
            changeData({ type, value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = { ...propertyData, organisationId: organisation?.id }

        if (defaultData)
            addRequest({ method: 'PATCH', data: { ...data, id: defaultData.id } })
        else
            addRequest({ method: 'POST', data })
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <VStack align={'stretch'} spacing={4}>
                    <FormControl size='sm' isRequired>
                        <FormLabelX label={'Name'} desc="property/hostel name" />
                        <Input placeholder={'name'} onChange={e => handleChange('name', e.target.value)} name='name' value={propertyData.name} />
                    </FormControl>
                    <FormControl size='sm' isRequired>
                        <FormLabelX label="Address" desc="property/hostel address" />
                        <Textarea name='streetAddress' onChange={e => handleChange('streetAddress', e.target.value)} placeholder={'address'} value={propertyData.streetAddress} />
                    </FormControl>
                    <FormControl size='sm'>
                        <FormLabelX label="Gender" desc="property/hostel for" />
                        <RadioGroup defaultValue='CO-ED' onChange={(value) => handleChange('gender', value)} value={propertyData.gender}>
                            <Stack spacing={5} direction='row'>
                                <Radio colorScheme='blue' value='MALE'>
                                    Male
                                </Radio>
                                <Radio colorScheme='blue' value='FEMALE'>
                                    Female
                                </Radio>
                                <Radio colorScheme='blue' value='CO-ED'>
                                    Coed
                                </Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                    {/* <FormControl size='sm' isRequired>
                        <FormLabelX label="Company" />
                        <Select onChange={(e) => handleChange('companyId', e.target.value)} value={propertyData.companyId} placeholder='select company'>
                            {allCompanies?.length ?
                                allCompanies.map(company =>
                                    <option value={company.id} key={company.id}>{company.name}</option>
                                )
                                :
                                null
                            }
                        </Select>
                    </FormControl> */}
                    <HStack spacing={4}>
                        <FormControl size='sm'>
                            <FormLabelX label="Town / Village" />
                            <Input placeholder={'town / village'} onChange={e => handleChange('town', e.target.value)} value={propertyData.town} />
                        </FormControl>
                        <FormControl size='sm'>
                            <FormLabelX label="State" />
                            <DropdownList placeholder={'select state'}
                                onChange={e => handleChange('state', e.id)}
                                data={allStates ? orderBy(allStates, 'name', 'asc') : []}
                                textField='name'
                                style={{ fontSize: 14 }}
                                dataKey={'id'}
                                value={propertyData.state}
                            />
                        </FormControl>
                    </HStack>

                    <HStack spacing={4}>
                        <FormControl size='sm'>
                            <FormLabelX label="District" />
                            <DropdownList placeholder={'select district'}
                                onChange={e => handleChange('district', e.id)}
                                style={{ fontSize: 14 }}
                                value={propertyData.district}
                                data={propertyData.state && find(allStates, s => s.id === propertyData.state)?.cities?.length ?
                                    orderBy(find(allStates, s => s.id === propertyData.state).cities, 'name', 'asc')
                                    : []
                                }
                                textField='name'
                                dataKey={'id'}
                            />
                        </FormControl>
                        <FormControl size='sm'>
                            <FormLabelX label="Pincode" />
                            <Input type={'number'} max={999999} placeholder={'pincode'} onChange={e => handleChange('pincode', e.target.value)} value={propertyData.pincode} />
                        </FormControl>
                    </HStack>
                </VStack>
                <br />
                <HStack justifyContent={['center', 'center', 'right']}>
                    {defaultData ?
                        <Button isLoading={addLoading}
                            _focus={{ boxShadow: 'none' }}
                            disabled={!propertyData.name && propertyData.streetAddress}
                            type='submit'
                        >
                            Save
                        </Button>
                        :
                        <Button
                            _focus={{ boxShadow: 'none' }} type='submit' isLoading={addLoading} disabled={!propertyData.name && propertyData.streetAddress} rightIcon={<AiOutlineDoubleRight />}>
                            Save and Next
                        </Button>
                    }
                </HStack>
            </form>
        </Box>
    )
}

export const FormLabelX = ({ label, desc }) => {
    return (
        <Box mb={2}>
            <FormLabel fontSize={'xl'} color='gray.700' fontWeight={'bold'} mb={0}>
                {label}
            </FormLabel>
            {
                desc &&
                <Box mt={-1} fontSize='sm' color='gray.600'>{desc}</Box>
            }
        </Box>
    )
}