import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, IconButton, Spinner, Text, Tooltip, VStack } from '@chakra-ui/react'
import { last } from 'lodash'

export const CustomContainer = ({ children, loading = false, breadcrumbs = [], title, breadcrumbIcon }) => {
    const navigate = useNavigate()
    const handleBack = () => {
        const indx = breadcrumbs.length - 2
        navigate(breadcrumbs[indx].src)
    }
    return (
        <Box background={'white'} pos='relative' px={[2, 2, 2, 4]} minH={500} py={6}>
            {loading ?
                <VStack pos={'absolute'} top={0} left={0} right={0} bottom={0} justify='center' align={'center'}>
                    <Spinner size='xl' />
                    {/* <Image src='/images/loader-icon.gif'/> */}
                    <Text>Fetching...</Text>
                </VStack>
                :
                <Box>
                    {breadcrumbs?.length ?
                        <>
                            <HStack align={'start'}>
                                {breadcrumbs.length === 1 ? null :
                                    <Tooltip label='back' placement='top'>
                                        <IconButton onClick={handleBack} colorScheme={'gray'} variant='ghost' fontSize={18} icon={<ArrowBackIcon />} />
                                    </Tooltip>
                                }
                                <VStack align={'start'} spacing={0}>
                                    <Text fontSize={'default'}>{last(breadcrumbs).title}</Text>
                                    <Breadcrumb separator={breadcrumbIcon} fontSize={'sm'} color='secondary'>
                                        {breadcrumbs.map(bread =>
                                            <BreadcrumbItem>
                                                <BreadcrumbLink onClick={() => navigate(bread.src)}>{bread.title}</BreadcrumbLink>
                                            </BreadcrumbItem>
                                        )}
                                    </Breadcrumb>
                                </VStack>
                            </HStack>
                            <br />
                        </>
                        :
                        null
                    }
                    {children}
                </Box>
            }
        </Box>
    )
}