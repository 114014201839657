import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import { Box, Center, HStack, Spinner, Text, useToast } from "@chakra-ui/react";
import { useSWRConfig } from "swr";
import _, { find, indexOf, size } from "lodash";

import { useAppContext, AppContext } from "./Context";
import {
  removeAuthorizationHeader,
  removePropertyHeader,
  setAuthorizationHeader,
  setPropertyHeader,
} from "../services/api";
import { useUserData } from "../services/api/useUserData";
import { currentPropId } from "../utils/useGetData";
import { useGetProperties } from "../services/api/useGetProperties";
import { ALL_USER_ROLES } from "../Constants";
global._ = _;

export const PROPERTY_KEY = "@PROPERTY";

export const LOGIN_TOKEN_KEY = "@login/hostel/admin";

export const getLoginData = () => {
  return localStorage.getItem(LOGIN_TOKEN_KEY);
};

export const AppProvider = ({ children, navigation }) => {
  const [appLoaded, setAppLoaded] = useState(false)
  const [allProperties, setAllProperties] = useState()

  const { cache } = useSWRConfig();
  const [loginTokens, setLoginTokens] = useState(false);

  const [curUserLoginDetail, setCurUserLoginDetail] = useState(null);

  const [currentProperty, setCurrentProperty] = useState(false);

  const { user, errorUser, organisation, isLoading } = useUserData(loginTokens);

  useEffect(() => {
    currentPropId.current = currentProperty?.id;
  }, [currentProperty]);

  const { loading: loadingProperties, data: properties } = useGetProperties(
    organisation?.id ? true : false,
    { organisationId: organisation?.id }
  );

  useEffect(() => {
    if (properties) {
      setAppLoaded(true)
    }
  }, [properties])

  useEffect(() => {
    const currentPropertyId = localStorage.getItem(PROPERTY_KEY);
    if (!loadingProperties && size(properties)) {
      let currentProp = find(properties, (d) => d.id === currentPropertyId);
      if (!currentProp) currentProp = properties[0];
      if (user.role !== ALL_USER_ROLES.OWNER) {
        const userAccessDetails = user.staffProfile?.staffAccessDetails;
        currentProp = userAccessDetails?.length > 0 ? userAccessDetails[0].property : {};
      }
      setCurrentProperty(currentProp);
      setPropertyHeader(currentProp.id);
      localStorage.setItem(PROPERTY_KEY, currentProp.id);
    } else {
      removePropertyHeader();
    }
    if (properties) {
      setAllProperties(properties)
    }

  }, [loadingProperties, user, properties]);

  const changeProperty = useCallback(
    (id) => {
      let currentProperty = find(properties, (d) => d.id === id);
      setCurrentProperty(currentProperty);
      localStorage.setItem(PROPERTY_KEY, currentProperty?.id);
      setPropertyHeader(currentProperty?.id);
    },
    [properties]
  );

  const loginSuccess = useCallback((loginRes) => {
    if (loginRes) {
      setAuthorizationHeader(loginRes.accessToken);
      setLoginTokens(loginRes);
    }
  }, []);

  useLayoutEffect(() => {
    const token = localStorage.getItem(LOGIN_TOKEN_KEY, {});
    try {
      let parsed = JSON.parse(token);
      if (parsed?.accessToken) {
        loginSuccess(parsed);
      } else {
        setAppLoaded(true)
      }
    } catch (e) {

    }
  }, [loginSuccess]);

  const toast = useToast();

  const logout = useCallback((e, skipApi = false) => {
    async function logoutfun() {
      if (skipApi === false) {
        LoadingRef.current?.show();
        LoadingRef.current?.hide();
        toast({
          status: "success",
          title: "Logout Success!",
          position: "top-right",
          duration: 1000,
        });
      }

      localStorage.removeItem(PROPERTY_KEY);
      localStorage.removeItem(LOGIN_TOKEN_KEY);
      removePropertyHeader();
      removeAuthorizationHeader();
      setCurrentProperty();
      cache.clear();
      setLoginTokens(false);
    }
    logoutfun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem(LOGIN_TOKEN_KEY, JSON.stringify(loginTokens));
    } else {
      if (errorUser) {
        logout()
        setAppLoaded(true)
      }
    }
  }, [user, errorUser, loginTokens, logout]);

  const hasPermission = useCallback((role) => {
    return user?.role === role
  }, [user])

  const hasAccess = useCallback((permission) => {
    if (user && currentProperty?.id) {
      const accesses = user.staffProfile?.staffAccessDetails?.length ?
        find(user.staffProfile.staffAccessDetails, d => d.propertyId === currentProperty.id)?.accesses_json
        : null
        return hasPermission(ALL_USER_ROLES.OWNER) || indexOf(accesses, permission) !== -1
    }
  }, [currentProperty?.id, user, hasPermission])

  const value = useMemo(
    () => ({
      curUserLoginDetail,
      isLoading,
      loadingProperties,
      logout,
      loginSuccess,
      user,
      loginTokens,
      changeProperty,
      currentProperty,
      properties: allProperties,
      setCurUserLoginDetail,
      hasPermission,
      hasAccess
    }),
    [hasAccess, user, loginTokens, allProperties, changeProperty, loginSuccess, logout, currentProperty, isLoading, loadingProperties, curUserLoginDetail, setCurUserLoginDetail, hasPermission]
  );

  return (
    <AppContext.Provider value={value}>
      {appLoaded ?
        children :
        !loadingProperties && !properties?.length ?
          <HStack align={'center'} justify='center' p={10} h='100vh'>
            <Text fontSize={'lg'} color='gray.500'>*No properties assigned to <b>{user?.name}</b></Text>
          </HStack>
          :
          <Spinner />
      }
      <LoadingPage ref={LoadingRef} />
    </AppContext.Provider>
  );
};

export const LoadingRef = React.createRef();

const logoutRef = React.createRef();

const LoadingPage = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  const { logout, updateDevice } = useAppContext();

  useImperativeHandle(ref, () => ({
    updateDevice,
    logoutForce: (m) => {
      if (Date.now() - logoutRef.current > 10000) {
        toast({
          id: "tforcelogout",
          title: "Logout",
          description:
            "Unauthorized or you account has been logged in some other device ",
          status: "error",
          position: "top",
          duration: 5000,
        });
        logout(null, true);
        logoutRef.current = Date.now();
      }
    },
    show: (m) => {
      setVisible(true);
    },
    hide: () => {
      setVisible(false);
    },
    showToast: (config) => {
      toast(config);
    },
  }));

  return (
    visible && (
      <Center
        bg="rgba(255,255,255,0.8)"
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="fixed"
      >
        <Spinner colorScheme="telegram" size="xl" />
      </Center>
    )
  );
});
