import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'

export function useUnitRoomTypes(q, params, revalidate) {
    const {data, error, loading, mutate} = useGetData(URIS.GET_UNIT_ROOM_TYPES+q, () => apis.getUnitRoomTypes(params), q, revalidate)

    return {
        data,
        error, loading, mutate
    }
}