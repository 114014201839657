export const ROUTES = {
    PROPERTIES: 'properties/:id/:tab',
    ADD_PROPERTIES: 'add-property/:type',
    TENANT: 'tenants/:page/:tab',
    TENANTPROFILE: 'tenant/profile/:id/:tab',
    DASHBOARD: '',
    WEBSITE: 'website',
    VISITOR_MANAGEMENT: 'in-out-management/:page',
    COMPANY: 'company',
    ADMIN_PROFILE: 'admin-profile',
    MARK_ATTENDANCE: 'mark-attendance/:page',
    WEBSITEHOMEPAGE: 'webhomepage',
    ADD_FIRST_PROPERTIE: 'add-first-property',
    ORGANISATION_RENT_CATEGORIES: 'rent-management',
    PROPERTY_RENT: 'property-rent-management/:id',
    MANAGE_STAFF: 'manage-staff',
    PAYMENT_REPORTS: 'payment-reports/:tab',
    COMPLAINT_REPORT: 'complaint-report',
    TENANT_OUT_REASONS: 'tenant-out-reasons',
    MESS: 'mess',
    MANAGE_MESS: "manage-mess/:id",
    MESS_BIOMETRIC_DEVICE: 'mess-biometric',
    MANAGE_BIOMETRIC_DEVICE:"device",
    MANAGE_DEVICE: "device/:id",
} 