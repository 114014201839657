import React, { useMemo } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { filter, find } from 'lodash'
import { useAppContext } from '../app/Context'
import { ALL_USER_ROLES } from '../Constants'
import { useGetProperties } from '../services/api/useGetProperties'
import { useUserData } from '../services/api/useUserData'


export const ActionHeader = ({ action, onAction, actionIcon, title = '', children, switchProperty }) => {
    const { currentProperty, changeProperty } = useAppContext()
    const { user } = useUserData(true)
    const { data: properties } = useGetProperties(true)

    const filterProperties = useMemo( () => {
        return filter(properties, p => {
            if(user.role === ALL_USER_ROLES.OWNER) return true;
            return find(user?.staffProfile?.staffAccessDetails, propery => propery.propertyId === p.id) ? true : false;
        })
    },[properties, user])

    const handleSelectProp = (e) => {
        changeProperty(e.target.value)
    }
    return (
        <Flex p={4} px={10} bg="white" wrap={'wrap'} justify={"space-between"} alignItems="center">
            <Box>
                <Text fontSize="lg" fontWeight={'bold'}>{title}</Text>
            </Box>
            <Box>
                {switchProperty ?
                    <Box display={{ base: "none", md: "flex" }}>
                        <Menu isLazy>
                            <MenuButton color={'defaultColor.500'} fontWeight='bold' variant='ghost'>{currentProperty?.name} {<ChevronDownIcon ml={1} fontSize={"2xl"} />}</MenuButton>
                            <MenuList onClick={handleSelectProp}>
                                <Box maxH={300} overflow='auto' p={2}>
                                {filterProperties?.length ?
                                    filterProperties.map(prop =>
                                        <MenuItem value={prop.id}>{prop.name}</MenuItem>
                                    )
                                    :
                                    null
                                }
                                </Box>
                            </MenuList>
                        </Menu>
                    </Box>
                    :
                    null
                }
                {
                    action && onAction ?
                        <Button leftIcon={actionIcon} colorScheme="blue" onClick={onAction}>{action}</Button>
                        : children
                }
            </Box>
        </Flex>
    )
}
