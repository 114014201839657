import { apiClient, URIS } from ".";

// login
const loginApi = (payload) => apiClient.post(URIS.LOGIN, payload);
const verifyOtpApi = (payload) => apiClient.post(URIS.OTP_VERIFY, payload);
const requestOtpApi = (payload) => apiClient.post(URIS.OTP, payload)
const getStatesApi = payload => apiClient.get(URIS.STATES, payload)
const getPropertiesApi = payload => apiClient.get(URIS.GET_PROPERTIES, payload)
const getOrganisationsApi = payload => apiClient.get(URIS.GET_ORGANISATIONS, payload)
const requestUserApi = (payload) => apiClient.get(URIS.ME, { ...payload });
const getSinglePropertiesApi = payload => apiClient.get(URIS.GET_SINGLE_PROPERTY, payload)
const getAllUnitsApi = payload => apiClient.get(URIS.GET_ALL_UNITS, payload)
const addTenantApi = payload => apiClient.post(URIS.ADD_TENANT, payload)
const getTenantApi = payload => apiClient.get(URIS.GET_TENANT, payload)
const updateTenantApi = payload => apiClient.patch(URIS.UPDATE_TENANT, payload)
const getTenantUnitApi = payload => apiClient.get(URIS.ASSING_TENANR, payload)
const TenantprofileApi = payload => apiClient.get(URIS.TENANT_PROFLE, payload)
const updateTenantprofileapi = payload => apiClient.patch(URIS.UPDATE_TENANT_PROFILE, payload)
const getGuardiansApi = payload => apiClient.get(URIS.ADD_GUARDIAN, payload)
const getAllGuardiansApi = payload => apiClient.get(URIS.GET_ALL_GUARDIAN, payload)
const getUnitApi = payload => apiClient.get(URIS.GET_UNIT, payload)
const getUnitTenants = payload => apiClient.get(URIS.GET_UNIT_TENANTS, payload)
const getSingleOrganisationApi = payload => apiClient.get(URIS.GET_SINGLE_ORGANISATION, payload)
const getAllDueCategoriesApi = payload => apiClient.get(URIS.GET_ALL_DUE_CATEGORIES, payload)
const getAllAttendanceApi = payload => apiClient.get(URIS.GET_ALL_ATTENDANCE, payload)
const getAttendanceRecordsApi = payload => apiClient.get(URIS.GET_ATTENDENCE_RECORDS, payload)
const getUnitRoomTypes = payload => apiClient.get(URIS.GET_UNIT_ROOM_TYPES, payload)
const getTenantsInOutApi = payload => apiClient.get(URIS.GET_TENANTS_IN_OUT, payload)
const getCompaniesApi = payload => apiClient.get(URIS.GET_COMPANIES, payload)
const getAllWebsiteListingApi = payload => apiClient.get(URIS.GET_WEBSITE_LISTING, payload)
const getInOutStatus = payload => apiClient.get(URIS.GET_INOUT_STATUS, payload)
const getSingleTenantProfile = payload => apiClient.get(URIS.SINGLE_TENANT_PROFILE, payload)
const getTenantDocumentsApi = payload => apiClient.get(URIS.GET_TENANT_DOCUMENTS, payload)
const getPropertyDueCategoriesApi = payload => apiClient.get(URIS.GET_PROPERTY_DUE_CATEGORIES, payload)
const getTenantSlipsApi = payload => apiClient.get(URIS.GET_TENANT_SLIPS, payload)
const getPaymentInstallmentDetailsApi = payload => apiClient.get(URIS.GET_PAYMENT_INSTALLMENT_DETAILS, payload)
const getPaymentDetailsApi = payload => apiClient.get(URIS.GET_PAYMENT_DETAILS, payload)
const getStaffDetailsApi = payload => apiClient.get(URIS.GET_STAFF, payload)
const getTenantWalletApi = payload => apiClient.get(URIS.GET_TENANT_WALLET, payload)
const getWalletTransactionsApi = payload => apiClient.get(URIS.GET_WALLET_TRANSACTIONS, payload)
const getPropertyDueStatusApi = payload => apiClient.get(URIS.GET_PROPERTY_DUE_STATUS, payload)
const getUnassignedTenantApi = payload => apiClient.get(URIS.GET_UNASSIGNED_TENANT, payload)
const getSearchedTenantsApi = payload => apiClient.get(URIS.GET_SEARCHED_TENANTS, payload)
const updateStaffApi = payload => apiClient.patch(URIS.UPDATE_STAFF, payload)
const postStaffacessApi = payload => apiClient.post(URIS.ADD_ASSIGN_PROPERTY, payload)
const getStaffacessApi = payload => apiClient.get(URIS.GET_ASSIGN_PROPERTY, payload)
const updateStaffacessApi = payload => apiClient.patch(URIS.UPDATE_ASSIGN_PROPERTY, payload)
const deleteStaffacessApi = payload => apiClient.delete(URIS.Delete_ASSIGN_PROPERTY, payload)
const getNextSequence = payload => apiClient.get(URIS.GET_NEXT_SEQUENCE, payload)
const getTenantDueReports = payload => apiClient.get(URIS.GET_TENANT_DUE_REPORT, payload)
const getStudentWiseDueReports = payload => apiClient.get(URIS.GET_TENANT_DUE_REPORT, payload)
const getPaymentHistoryApi = payload => apiClient.get(URIS.GET_PAYMENT_HISTORY, payload)
const getRefundHistoryApi = payload => apiClient.get(URIS.GET_REFUND_HISTORY, payload)

const addMessApi = payload => apiClient.post(URIS.ADD_MESS, payload)
const getPropertyMessApi = payload => apiClient.get(URIS.GET_PROPERTY_MESS, payload)
const getSingleMessApi = payload => apiClient.get(URIS.ADD_MESS, payload)
const updateFoodMenuApi = payload => apiClient.patch(URIS.UPDATE_FOOD_MENU, payload)
const addMessAttendance = payload => apiClient.post(URIS.MESS_ATTENDANCE, payload)
const getMessAttendance = payload => apiClient.get(URIS.GET_MESS_ATTENDANCE, payload)
const getComplaintsApi = payload => apiClient.get(URIS.GET_COMPLAINTS, payload)
const getDashboardApi = payload => apiClient.get(URIS.GET_DASHBOARD, payload)
const getCompaniesPaymentConfig = payload => apiClient.get(URIS.GET_COMPANY_PAYMENT_CONFIGS, payload)
const getMessDeviceApi = payload => apiClient.get(URIS.MESS_DEVICE_ALL, payload)
const getMessPropertyApi = payload => apiClient.get(URIS.MESS_PROPERTY_ALL, payload)
const getMessFoodMenuApi = payload => apiClient.get(URIS.FOOD_MENU_ALL, payload)
const getMessDeviceTenantsApi = payload => apiClient.get(URIS.MESS_DEVICE_USERS, payload)
const getMessAttendanceReportApi = payload => apiClient.get(URIS.MESS_ATTENDANCE_REPORT, payload)

const getTenantOutReasons = payload => apiClient.get(URIS.GET_TENANT_OUT_REASONS, payload)
const getPaymentSummary = payload => apiClient.get(URIS.GET_PAYMENT_SUMMARY, payload)

//id card history
const createIdCardHistory = payload => apiClient.post(URIS.POST_ID_CARD_HISTORY, payload)
const getIdCardHistory = payload => apiClient.get(URIS.GET_ID_CARD_HISTORY, payload)

const getDeviceDataApi = payload => apiClient.get(URIS.GET_DEVICE_DATA, payload)
const getSingleDeviceDataApi = payload => apiClient.get(URIS.ADD_DEVICE_DATA, payload)
const getDevicePropertyApi = payload => apiClient.get(URIS.GET_DEVICE_PROPERTY, payload)
const getDeviceTenantsApi = payload => apiClient.get(URIS.GET_DEVICE_TENANTS, payload)
const tenantLoginApi = payload => apiClient.post(URIS.TENANT_PAYMENT_LOGIN, payload)
const tenantVerifyOtpApi = payload => apiClient.post(URIS.VERIFY_TENANT_OTP, payload)
const verifyOnlinePaymemnt = payload => apiClient.post(URIS.VERIFY_PAYMENT_ONLINE, payload)
const getOnlinePaymentsApi = payload => apiClient.get(URIS.GET_ONLINE_PAYMENTS, payload)
const getOneOnlinePaymentApi = payload => apiClient.get(URIS.GET_ONLINE_PAYMENT, payload)



export const apis = {
    getCompaniesPaymentConfig,
    getDashboardApi,
    getPaymentHistoryApi,
    getTenantDueReports,
    getStudentWiseDueReports,
    getNextSequence,
    getSearchedTenantsApi,
    getUnassignedTenantApi,
    getPropertyDueStatusApi,
    getWalletTransactionsApi,
    getTenantWalletApi,
    getCompaniesApi,
    getTenantsInOutApi,
    loginApi,
    requestOtpApi,
    verifyOtpApi,
    getStatesApi,
    getPropertiesApi,
    getOrganisationsApi,
    requestUserApi,
    getSinglePropertiesApi,
    getAllUnitsApi,
    addTenantApi,
    getTenantApi,
    updateTenantApi,
    getTenantUnitApi,
    TenantprofileApi,
    updateTenantprofileapi,
    getGuardiansApi,
    getAllGuardiansApi,
    getUnitApi,
    getUnitTenants,
    getSingleOrganisationApi,
    getAllDueCategoriesApi,
    getAllAttendanceApi,
    getAttendanceRecordsApi,
    getUnitRoomTypes,
    getAllWebsiteListingApi,
    getInOutStatus,
    getSingleTenantProfile,
    getTenantDocumentsApi,
    getPropertyDueCategoriesApi,
    getTenantSlipsApi,
    getPaymentInstallmentDetailsApi,
    getPaymentDetailsApi,
    getStaffDetailsApi,
    updateStaffApi,
    postStaffacessApi,
    getStaffacessApi,
    updateStaffacessApi,
    deleteStaffacessApi,
    getRefundHistoryApi,

    addMessApi,
    getPropertyMessApi,
    getSingleMessApi,
    updateFoodMenuApi,
    addMessAttendance,
    getMessAttendance,
    getComplaintsApi,
    getMessDeviceApi,
    getMessPropertyApi,
    getMessFoodMenuApi,
    getMessDeviceTenantsApi,
    getMessDeviceTenantsApi,
    getMessAttendanceReportApi,

    getTenantOutReasons,

    getPaymentSummary,

    createIdCardHistory,
    getIdCardHistory,
    getDeviceDataApi,
    getSingleDeviceDataApi,
    getDevicePropertyApi,
    getDeviceTenantsApi,
    tenantLoginApi,
    tenantVerifyOtpApi,
    verifyOnlinePaymemnt,
    getOnlinePaymentsApi,
    getOneOnlinePaymentApi
}