import _, { sumBy, uniq } from "lodash"
import moment from "moment"
import { useLocation } from "react-router-dom"
export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search)
}

export const floorName = (f) => {
    const floor = parseInt(f)
    const name = floor === -1 ? 'Basement' : floor === 0 ? 'Ground Floor' : floor === 1 ? '1st Floor' : floor === 2 ? '2nd Floor' : floor === 3 ? '3rd Floor' : floor + 'th floor'
    return name
}

export const getfloors = (allUnits = []) => {
    if (allUnits) {
        const floors = allUnits.length ? uniq(allUnits.map(d => d.floorNo)) : []
        return floors
    }
}

export const dateFormat = date => date ? moment(date).format('YYYY-MM-DD') : null
export const displayDateFormat = (date, format) => date ? moment(date).format(format || 'DD-MM-YYYY') : null
export const getPaybleAmount = (category) => !category?.amount ? 0 : (category?.amount - (category?.concession ? sumBy(category.concession, 'concession') : 0))
export const mobileView = ({
    visibility: ['visible', 'visible', 'visible', 'hidden'],
    display: ['block', 'block', 'block', 'none']
})

export const webView = ({
    visibility: ['hidden', 'hidden', 'hidden', 'visible'],
    display: ['none', 'none', 'none', 'block',]
})

export const isCompanyGstOn = (company) => {
    if(!company) return false;
    return (
        company.gstin || company.state_code || company.cin || company.place_of_service
    ) ? true : false;
} 


export const inWords = (num) => {
    var a = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ", "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen " ];
    var b = ["","","Twenty ","Thirty ","Forty ","Fifty ","Sixty ","Seventy ", "Eighty ", "Ninety " ]

    if(!num) return '';
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'ONLY ' : '';
    return str;
}