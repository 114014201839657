import React, { useCallback, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons';
import {
  FiHome,
  FiCompass,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import { BsCardChecklist, BsClipboardCheck, BsFillDeviceSsdFill, BsGlobe, BsPeople } from "react-icons/bs";
import { BiBuilding, BiExit } from "react-icons/bi";
import { MdOutlineFoodBank } from "react-icons/md";
import { RouterLink } from "./RouterLink";
import { useAppContext } from "../app/Context";
import { AiOutlineCalendar, AiOutlineUser } from "react-icons/ai";
import { ROUTES } from "../Constants/Routes";
import { useNavigate } from "react-router-dom";
import { URIS } from "../services/api";
import { useApiRequest } from "../services/api/useApiRequest";
import { currentPropId } from "../utils/useGetData";
import { GiReceiveMoney } from "react-icons/gi";
import { ALL_USER_ROLES, staffPermissions } from "../Constants";
import { filter, find, flatMap, intersection, uniq, uniqBy } from "lodash";
import { useMemo } from "react";
import { useUserData } from "../services/api/useUserData";
import { useRef } from "react";
import { useEffect } from "react";

const LinkItems = [
  {
    id: 1, name: "Dashboard", icon: FiHome, href: ROUTES.DASHBOARD,
    permissions: [staffPermissions.DASHBOARD]

  },
  {
    id: 2, name: "Properties", icon: FiCompass, href: ROUTES.PROPERTIES,
    permissions: [staffPermissions.PROPERTY]
  },
  {
    id: 3, name: "Tenant", icon: BsPeople, href: 'tenants/1/0',
    permissions: [staffPermissions.TENANT]
  },
  {
    id: 4, name: "Mark Attendance", icon: BsClipboardCheck, href: 'mark-attendance/1',
    permissions: [staffPermissions.ATTENDANCE]
  },
  {
    id: 5, name: "Tenant In-Out", icon: BiExit, href: 'in-out-management/1',
    permissions: [staffPermissions.IN_OUT]
  },
  {
    id: 6, name: "Website", icon: BsGlobe, href: ROUTES.WEBSITE,
    permissions: [staffPermissions.WEBSITE]
  },
  {
    id: 7, name: "Company", icon: BiBuilding, href: ROUTES.COMPANY,
    permissions: [staffPermissions.COMPANY]
  },
  {
    id: 8, name: "Rent Categories", icon: GiReceiveMoney, href: ROUTES.ORGANISATION_RENT_CATEGORIES,
    permissions: [staffPermissions.RENT_CATEGORIES]
  },
  {
    id: 9, name: "Staff", icon: AiOutlineUser, href: ROUTES.MANAGE_STAFF,
    permissions: [staffPermissions.STAFF]
  },
  {
    id: 10, name: "Payment Reports", icon: AiOutlineCalendar, href: 'payment-reports/due-payments',
    permissions: [staffPermissions.ALL_PAYMENTS, staffPermissions.SELF_PAYMENTS],
  },
  {
    id: 11, name: "Complaint Report", icon: AiOutlineCalendar, href: 'complaint-report',
    permissions: [staffPermissions.STAFF]
  },
  {
    id: 12, name: "Tenant Out Reasons", icon: BsCardChecklist, href: 'tenant-out-reasons',
    permissions: [staffPermissions.STAFF]
  },
  {
    id: 13, name: "Mess", icon: MdOutlineFoodBank, href: 'mess',
    permissions: [staffPermissions.MESS]
  },
  {
    id: 14, name: "Biometric Device", icon: BsFillDeviceSsdFill, href: 'device',
    permissions: [staffPermissions.DEVICE]
  },
];

export function SidebarWithHeader({ children, key, nosidebar }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box key={key} minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} px={3}>
        {children}
      </Box>
    </Box>
  );
}


const SidebarContent = ({ onClose, ...rest }) => {

  const { user } = useUserData(true)

  const isNavPermission = useCallback((item) => {
    let isValid = false;
    const role = user.role;
    if (role === ALL_USER_ROLES.OWNER) return true;
    const userPermissions = uniq(flatMap(filter(user.staffProfile?.staffAccessDetails, a => a.accesses_json), d => d.accesses_json))

    if (find(item.permissions, p => userPermissions.indexOf(p) !== -1) !== undefined)
      isValid = true;

    return isValid;

  }, [user])

  const AuthNavItems = useMemo(() => {
    const authLinks = filter(LinkItems, n => isNavPermission(n));
    const roleLinks = LinkItems.filter(d => intersection(d.rolePermissions, [user?.role])?.length)
    return uniqBy([...authLinks, ...roleLinks], 'id');
  }, [isNavPermission, user])

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
      overflowY={"scroll"} maxH={"100vh"} className="scrollbar-1"
    >
      <Flex h="20" w={"full"} alignItems="center" px="8" justifyContent="space-between" position={"sticky"} top={"0px"} bgColor={"white"}>
        <Flex w="full" >
          <img src="/favicon.ico" alt='favicon' width={"35px"} />
          <Text ml={1} fontSize="xl" fontFamily="monospace" fontWeight="bold">
            Hostellog
          </Text>
        </Flex>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box>
        {AuthNavItems.map((link) => (
          <NavItem key={link.name} href={link.href} onClose={onClose} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, href = "#", onClose, children, ...rest }) => {
  return (
    <RouterLink to={href}>
      <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
        <Flex
          onClick={onClose}
          align="center"
          p={3}
          mx="4"
          borderRadius="sm"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "defaultColor.400",
            color: "white",
          }}
          transition='all .3s'
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    </RouterLink>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const { logout, user } = useAppContext()
  const _onError = useCallback((data, response) => {
    toast({
      status: "error",
      title: "Logout Failed",
      description: response?.message,
      isClosable: true,
      position: "top",
      duration: 1000,
    })

  }, [toast])

  const { request: logoutAction } = useApiRequest(URIS.LOGOUT, {
    onCompleted: d => { logout() },
    onError: _onError,
    showLoader: true,
  })

  const _logout = () => {
    logoutAction()
  }

  return (
    <Flex
      key={currentPropId.current}
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
      pos='relative'
    >
      <HStack spacing={4}>
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          <Flex align='center'>
            <img src="/favicon.ico" alt='favicon' width={"35px"} />
            <Text ml={1}>
              Hostellog
            </Text>
          </Flex>
        </Text>
      </HStack>
      <HStack spacing={{ base: "0", md: "6" }}>
        <Box cursor='pointer'>
          <Box display={{ base: 'block', md: 'none' }}>
            <MobileSearchTenantInput />
          </Box>
          <Box display={{ base: 'none', md: 'block' }}>
            <SearchTenantInput />
          </Box>
        </Box>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={user.avatar}
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {user.role}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={() => navigate(ROUTES.ADMIN_PROFILE)}>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuDivider />
              <MenuItem onClick={_logout} >Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SearchTenantInput = () => {
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('')
  const handleSearchTenant = (e) => {
    e.preventDefault();
    const type = searchKey.match(/^[0-9]+/) ? searchKey.length === 10 ? 'contact' : 'registration' : 'name';
    navigate('/tenants/1/2?search=' + searchKey + '&type=' + type);
    setSearchKey('');
  }
  return (
    <Box pos='relative'>
      <form onSubmit={handleSearchTenant}>
        <InputGroup>
          <InputLeftAddon bg='white' border='none' children={<SearchIcon color='defaultColor.500' />} />
          <Input
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            variant={'flushed'} placeholder='Search tenant' />
        </InputGroup>
      </form>
    </Box>
  )
}

const MobileSearchTenantInput = () => {
  const navigate = useNavigate();
  const inputRef = useRef()
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    if (showSearchInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearchInput])

  const handleSearchTenant = (e) => {
    e.preventDefault();
    navigate('/tenants/1/2?type=name&search=' + searchKey);
    setSearchKey('');
  }
  return (
    <Box>
      <Box onClick={() => setShowSearchInput(p => !p)}>
        <IconButton bg='white' border='none' icon={<SearchIcon color='defaultColor.500' />} />
      </Box>
      <Box zIndex={99} maxH={showSearchInput ? '100vh' : '0px'} overflow='hidden' transition={'all 0.3s 0s'} opacity={showSearchInput ? '1' : '0'} pos='absolute' bg='white' left={0} top={'80%'} w={'100%'} px={2} pb={2} pt={showSearchInput ? 4 : 0}>
        <form onSubmit={handleSearchTenant}>
          <InputGroup>
            <InputLeftAddon bg='white' border='none' children={<SearchIcon color='defaultColor.500' />} />

            <Input
              ref={inputRef}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              variant={'flushed'} placeholder='Search tenant' />
          </InputGroup>
        </form>
      </Box>
    </Box>
  )
}