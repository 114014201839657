import React, { useEffect } from "react"
import { useToast } from "@chakra-ui/react"
import useSWR from "swr"

export const currentPropId = React.createRef()

export function useGetData(key, api, updateKey, revalidate, extra) {
    const { data, error, isValidating, mutate } = useSWR(updateKey ? key + currentPropId.current : null, api, {
        revalidateOnFocus: false,
        revalidateIfStale: revalidate || false,
        ...extra
    })


    const toast = useToast()

    useEffect(() => {
        if (error) {
            toast({
                status: "error",
                title: "Failed",
            })
        }

    }, [error, toast])

    return { data: data?.data, error, loading: isValidating, mutate }
}