
import React, { useState } from 'react'
import { Box, Button, FormControl, FormLabel, HStack, Select } from '@chakra-ui/react'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import useSWR from 'swr'
import { URIS } from '../../services/api'
import { useApiRequest } from '../../services/api/useApiRequest'

export const AddFloor = ({ nextStep, selectFloorsCount, currentProperty }) => {
    const [floorsCount, setFloors] = useState()

    const { mutate } = useSWR(URIS.GET_SINGLE_PROPERTY + currentProperty?.id)

    const { request: updateRequest, loading: updateLoading } = useApiRequest(URIS.ADD_PROPERTY, {
        onCompleted: () => {
            mutate()
            nextStep(2)
        },
        onError: () => { },
        showAlert: true
    })

    const handleSelectFloors = (e) => {
        setFloors(e.target.value)
    }

    const handleNext = () => {
        selectFloorsCount(floorsCount)
    }

    return (
        <Box>
            <FormControl>
                <FormLabel fontSize={'xl'} color='gray.500' fontWeight={'bold'} mb={4}>
                    How many floors are in your property/hostel
                </FormLabel>
                <Select onChange={handleSelectFloors} placeholder='Select Total Floor Count'>
                    {Array.from({ length: 10 }, (d, i) => {
                        return (
                            <option value={i + 1} key={i}>
                                {i + 1} Floors
                            </option>
                        )
                    }
                    )}
                </Select>
            </FormControl>
            <br /><br />
            <HStack justifyContent={['center', 'center', 'right']}>
                <Button _focus={{ boxShadow: 'none' }} onClick={handleNext} isLoading={updateLoading} disabled={!floorsCount} rightIcon={<AiOutlineDoubleRight />}>
                    Next
                </Button>
            </HStack>
        </Box>
    )
}