import moment from "moment";

export const SHARINGS = {
  1: { count: 1, type: "single", title: "Single Room", detail: 'Room only allocate to a single person' },
  2: { count: 2, type: "two", title: "2 Sharing", detail: 'Room shared between two persons' },
  3: { count: 3, type: "three", title: "3 Sharing", detail: 'Room shared between three persons' },
  4: { count: 4, type: "four", title: "4 Sharing", detail: 'Room shared between four persons' },
};

export const PAYMENT_STATUS = { GENERATED: 'generated', PAID: 'paid', CANCELLED: 'cancelled', PARTIAL_PAID: 'partial_paid', PENDING: 'pending' }
export const INSTALLMENT_TYPE = { ONE_TIME: 'OT', MONTHLY: 'M', QUARTERLY: 'Q', HALF_YEARLY: 'HY', ANNUALLY: 'A' }
export const PAYMENT_METHODS = { CASH: 'offline', WALLET: 'wallet', CHEQUE: 'cheque', DD: 'dd', NEFT: 'neft', RTGS: 'rtgs', ONLINE: 'online', OTHER: 'other' }

export const staffPermissions = { 
  DASHBOARD:'dashboard',
  TENANT: 'tenant', 
  PAYMENT: 'payment', 
  PROPERTY: 'property', 
  ATTENDANCE: 'attendance', 
  IN_OUT: 'in_out', 
  WEBSITE: 'website', 
  STAFF: 'staff', 
  COMPANY: 'company',
  ALL_PAYMENTS:'paymentReportAll',
  SELF_PAYMENTS:'paymentReportSelf',
  CHEQUE_STATUS:'chequeStatus',
  ADD_TENANT:'addTenant',
  COLLECT_PAYMENT:'collectPayment',
  REPRINT_SLIP:'reprintSlip',
  EDIT_SLIP_NUMBER:'editSlipNo',
  EDIT_CATEGORY_DUE_AMOUNT:'editCategoryDueAmount',
  CONCESSION_ADD:'concessionAdd',
  CANCEL_DUE:'cancelDue',
  CANCEL_PAYMENT:'cancelPayment',
  CHANGE_NEXT_SLIP_PAYMENT_AMOUNT:'changeNextSlipPaymentAmount',
  MESS: 'mess',
  ID_CARD: 'id-card',
  DEVICE:"device",
  VIEW_TENANT_CONTACT:"viewTenantContact",
  EDIT_TENANT_NAME:"editTenantName",
}

export const allPaymentModes = {
  offline: { id: 1, name: 'Cash', type: 'offline' },
  // wallet:{id:8, name:'Wallet', type:'wallet'},
  cheque: { id: 2, name: 'Cheque', type: 'cheque' },
  dd: { id: 3, name: 'DD', type: 'dd' },
  neft: { id: 4, name: 'NEFT', type: 'neft' },
  rtgs: { id: 5, name: 'RTGS', type: 'rtgs' },
  online: { id: 6, name: 'Online', type: 'online' },
  other: { id: 7, name: 'Other', type: 'other' },
}
export const dateRange = [
  {
    id: 1,
    title: "Last 6 hours",
    startTime: moment().subtract(6, "hours").format("YYYY-MM-DD HH:mm:ss"),
    endTime: moment().format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    id: 2,
    title: "Today",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  {
    id: 3,
    title: "Last 2 Days",
    startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  {
    id: 4,
    title: "Last 7 Days",
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
];

export const installment_types = {
  OT: { title: "One Time", value: "OT" },
  M: { title: "Monthly", value: "M" },
};

export const applicable_types = {
  1: { title: 'Room', type: 'unit', value: 1 },
  0: { title: 'Tenant', type: 'tenant', value: 0 }
};

export const billing_types = {
  0: { title: "One time" },
  1: { title: "Monthly" },
};

export const PUBLIC_USER_ROLES = {
  STAFF: 'STAFF',
  MESS: 'MESS',
  WARDEN: "WARDEN",
  INCHARGE: "INCHARGE",
  ACCOUNTANT: 'ACCOUNTANT',
  SUPPER_ACCOUNTANT: 'SUPPER_ACCOUNTANT'
}

export const ALL_USER_ROLES = {
  ...PUBLIC_USER_ROLES,
  OWNER: 'OWNER',
}

export const themes = {
  orange: { //orange
    50: '#FFFAF0',
    100: '#FEEBC8',
    400: '#F6AD55',
    500: '#DD6B20',
    600: '#C05621'
  },
  pink: { //pink
    50: '#FFF5F7',
    100: '#FED7E2',
    400: '#ED64A6',
    500: '#D53F8C',
    600: '#B83280'
  },
  purple: {  //purple
    50: '#FAF5FF',
    100: '#E9D8FD',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#6B46C1'
  },
  green: {  //green
    50: '#F0FFF4',
    100: '#C6F6D5',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A'
  },
  red: {  //red
    50: '#FFF5F5',
    100: '#FED7D7',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030'
  },
  yellow: {  //yellow
    50: '#FFFFF0',
    100: '#FEFCBF',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F'
  },
  blue: {  //blue
    50: '#EBF8FF',
    100: '#BEE3F8',
    400: '#4299E1',
    500: '#3182CE',
    600: '#2B6CB0'
  },
  teal: {  //teal
    50: '#E6FFFA',
    100: '#B2F5EA',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B'
  },
}

export const ac_room_types = [
  { id: 1, title: 'Sharing 1', sharing: 1 },
  { id: 2, title: 'Sharing 2', sharing: 2 },
  { id: 3, title: 'Sharing 3', sharing: 3 },
  { id: 4, title: 'Sharing 4', sharing: 4 }
]

export const nonac_room_types = [
  { id: 1, title: 'Sharing 1', sharing: 1 },
  { id: 2, title: 'Sharing 2', sharing: 2 },
  { id: 3, title: 'Sharing 3', sharing: 3 },
  { id: 4, title: 'Sharing 4', sharing: 4 }
]