import React, { useState } from 'react'
import { Button, FormControl, FormLabel, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select } from '@chakra-ui/react'
import { floorName } from '../../utils/Helper'

export const CopyModal = ({visible, closeModal, currentFloor, allFloors, submitCopy}) => {
    const [selectedFloor, setFloor] = useState()

    const handleSubmit = () => {
        if(selectedFloor)
            submitCopy(selectedFloor)
    }

    const handleChange = (e) => {
        setFloor(e.target.value)
    }

    return(
        <Modal isOpen={visible} onClose={closeModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Copy {floorName(currentFloor.floorNo)} in</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <FormControl>
                        <FormLabel>Select Destination Floor:</FormLabel>
                        <Select placeholder='Select floor to copy ' onChange={handleChange}>
                            {allFloors.map(f => 
                                <option value={f.floorNo} key={f.floorNo}>{floorName(f.floorNo)}</option>
                            )}
                        </Select>
                    </FormControl>
                    <br/>
                </ModalBody>
                <ModalFooter>
                    <HStack>
                        <Button onClick={closeModal} colorScheme={'gray'}>Cancel</Button>
                        <Button onClick={handleSubmit}>Done</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}