import { URIS } from '.'
import { useGetData } from '../../utils/useGetData'
import { apis } from './apis'
import { useUserData } from './useUserData'


export function useCompanyData(q, params) {
    const {organisation} = useUserData(true)

    const {data, error, loading, mutate} = useGetData(URIS.GET_COMPANIES+q, () => apis.getCompaniesApi({organisationId:organisation?.id}), q)

    return {
        data, error, loading, mutate
    }
}