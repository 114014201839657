import { create } from "apisauce";
import { LoadingRef } from "../../app/AppProvider";
import apiMonitor from "./monitor";

// export const BaseURL = "http://localhost:4000";
export const BaseURL="https://api.hostel.next.gcisikar.com"
// export const BaseURL = "https://api.hostellog.in";
// export const BaseURL = "http://hostel.tutorlog.in";
// export const BaseURL = "http://api.stage.hostel.tutorlog.in/";
export const URIS = {
  STATES: "/app/states",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  SIGNUP: "/user",
  ME: "/auth/me",
  UPDATE_USER: '/user/update',
  UPLOAD_FILE: '/app/file',
  GET_PROPERTIES: '/property/all',
  ADD_PROPERTY: '/property',
  GET_ORGANISATIONS: '/organisation/all',
  GET_SINGLE_ORGANISATION: '/organisation',
  GET_SINGLE_PROPERTY: '/property',
  ADD_ROOMS: '/unit/multiple',
  GET_ALL_UNITS: '/unit/all',
  GET_UNIT: '/unit',
  ADD_TENANT: '/tenant-profile',
  GET_TENANT: '/tenant-profile/all',
  UPDATE_TENANT: '/tenant-profile',
  ASSING_TENANR: '/unit-tenant',
  TENANT_PROFLE: '/tenant-profile',
  UPDATE_TENANT_PROFILE: '/tenant-profile',
  ADD_GUARDIAN: '/tenant-guardian',
  GET_ALL_GUARDIAN: '/tenant-guardian/all',
  ADD_ROOM: '/unit',
  GET_UNIT_TENANTS: '/unit-tenant/all',
  GET_ALL_DUE_CATEGORIES: '/due-categories/all',
  UPDATE_CATEGORIES: '/due-categories/multiple',
  UPDATE_ORGANISATION: '/organisation',
  WEBSITE_PROPERTY: '/website-listing',
  GET_WEBSITE_LISTING: 'website-listing/all',
  CREATE_ATTENDANCE: '/attendance',
  GET_ALL_ATTENDANCE: '/attendance/all',
  CREATE_ATTENDANCE_RECORD: '/attendance-records',
  GET_ATTENDENCE_RECORDS: '/attendance-records/all',
  GET_UNIT_ROOM_TYPES: '/unit-room-type/all',
  ADD_UNIT_ROOM_TYPE: '/unit-room-type',
  CREATE_IN_OUT_ENTRY: '/tenant-in-out/multiple',
  GET_TENANTS_IN_OUT: '/tenant-in-out/all',
  ADD_COMPANY: '/company',
  GET_COMPANIES: '/company/all',
  GET_INOUT_STATUS: '/unit-tenant/in-out-status',
  SEND_OTP: '/auth/otp',
  VERIFY_OTP: '/user/verify-contact',
  SINGLE_TENANT_PROFILE: '/tenant-profile/tenant',
  TENANT_DOCUMENTS: '/tenant-document',
  GET_TENANT_DOCUMENTS: '/tenant-document/all',
  ADD_CATEGORY: '/due-categories',
  ADD_SINGLE_PROPERTY_DUE_CATEGORY: '/property-due-categories',
  ADD_PROPERTY_DUE_CATEGORY: '/property-due-categories/multiple',
  GET_PROPERTY_DUE_CATEGORIES: '/property-due-categories/all',
  ADD_TENANT_DUE: '/tenant-dues/multiple',
  GET_TENANT_SLIPS: '/tenant-dues/all',
  UPDATE_TENANT_DUE: '/tenant-dues',
  ADD_PAYMENT: '/payment-details',
  ADD_PAYMENT_ONLINE: '/online-payment',
  VERIFY_PAYMENT_ONLINE: '/online-payment/verify',
  GET_ONLINE_PAYMENTS: '/online-payment/tenant',
  GET_ONLINE_PAYMENT: '/online-payment/one',
  REFETCH_ONLINE_PAYMENT: '/online-payment/refetch',
  GET_PAYMENT_INSTALLMENT_DETAILS: '/payment-receipt-details/all',
  GET_PAYMENT_DETAILS: '/payment-details/all',
  ADD_STAFF: '/staff-profile',
  GET_STAFF: '/staff-profile/all',
  UPDATE_STAFF: '/staff-profile',
  ADD_ASSIGN_PROPERTY: '/staff-access/multiple',
  // GET_ASSIGN_PROPERTY:'staff-access/all',
  GET_TENANT_WALLET: '/wallet-user/all',
  ADD_WALLET: '/wallet-user',
  ADD_TRANSACTION: '/wallet-transaction',
  GET_WALLET_TRANSACTIONS: '/wallet-transaction/all',
  GET_PROPERTY_DUE_STATUS: '/property-due-status/all',
  ADD_PROPERTY_DUE_STATUS: '/property-due-status/multiple',
  GET_UNASSIGNED_TENANT: '/tenant-profile/unassigned',
  GET_SEARCHED_TENANTS: '/tenant-profile/search-to-assign',
  DELETE_ASSIGN_PROPERTY: '/staff-access',
  GET_NEXT_SEQUENCE: '/sequence/next-sequence-no',
  GET_TENANT_DUE_REPORT: '/tenant-dues/report',
  GET_PAYMENT_HISTORY: '/payment-details/report',
  ADD_DUE_NEXT_SLIP: '/tenant-dues/generate-next-slip',
  ADD_CONCESSION: '/tenant-due-concession',
  CANCEL_PAYMENT: '/payment-details',
  REFUND_PAYMENT: '/tenant-refund-receipt',
  GET_REFUND_HISTORY: '/tenant-refund-receipt/all',

  ADD_MESS: '/mess',
  GET_PROPERTY_MESS: '/mess/all',
  UPDATE_FOOD_MENU: '/food-menu',
  FOOD_MENU_ALL: '/food-menu/all',
  MESS_ATTENDANCE: '/attendance-mess',
  GET_MESS_ATTENDANCE: '/attendance-mess/all',
  COPY_MESS: 'mess/copy',
  MESS_DEVICE_ALL: "/mess-device/all",
  MESS_DEVICE: "/mess-device",
  MESS_PROPERTY: "/mess-property",
  MESS_PROPERTY_ALL: "/mess-property/all",
  MESS_PROPERTY_SYNC_ON_DEVICE: "/device-user/property",
  MESS_DEVICE_USERS: "device-user/all",
  MESS_ATTENDANCE_REPORT: "attendance-mess/all",

  SEND_OTP_DELETE_PAYMENT: 'auth/otp/slip-cancel',
  VARIFY_OTP_DELETE_PAYMENT: 'auth/verify-otp',

  ADD_TENANT_BY_REGISTRATION_NUMBER: 'tenant-profile/upload-from-registration-no',
  GET_COMPLAINTS: 'complaint/all',
  UPDATE_COMPLAINT: 'complaint',
  GET_DASHBOARD: '/app/dashboard',
  ADD_PAYMENT_CONFIG: '/payment-config',
  GET_COMPANY_PAYMENT_CONFIGS: '/payment-config/all',

  GET_TENANT_OUT_REASONS: 'tenant-out-reasons/all',
  ADD_TENANT_OUT_REASON: 'tenant-out-reasons',
  UPDATE_TENANT_OUT_REASON: 'tenant-out-reasons',
  GET_PAYMENT_SUMMARY: 'payment-details/summary',

  POST_ID_CARD_HISTORY: "print-log",
  GET_ID_CARD_HISTORY: "print-log/all",

  GET_DEVICE_DATA: "biometric-device/all",
  ADD_DEVICE_DATA: "biometric-device",

  GET_DEVICE_PROPERTY: "tenant-in-out-device/all",
  POST_DEVICE_PROPERTY: "tenant-in-out-device",
  DELETE_DEVICE_PROPERTY: "tenant-in-out-device",

  GET_DEVICE_TENANTS: "device-user/all",
  DEVICE_PROPERTY_SYNC: "device-user/property",
  TENANT_PAYMENT_LOGIN: 'auth/tenant',
  VERIFY_TENANT_OTP: 'auth/verify-otp-login/tenant'
};

let api = create({
  baseURL: BaseURL,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
  timeout: 45000,
});

api.addMonitor(apiMonitor);
// setInterceptor(api);

api.axiosInstance.interceptors.response.use(
  (response) => {
    console.log("res, interceptor", { response });
    return response;
  },
  async (error) => {
    let originalRequest = error.config;
    console.log("LOG_goterror", originalRequest, error.response);
    let isunauth = error.response && error.response.status === 401;
    if (
      isunauth &&
      !originalRequest._retry &&
      !originalRequest.headers._retry
    ) {
      console.log("LOG_status401_error", "-> refreshing now ");
      originalRequest._retry = true;
      LoadingRef.current.logoutForce();
      //get refresh token
      // const credentials = undefined // await getLoginCredentials();

      // if (credentials) {
      //   // const {refresh_token} = credentials;
      //   // // api call for access token using refresh token

      //   // return new Promise(async (resolve, reject) => {
      //   //   const response = await api.post(
      //   //     URIS.REFRESH,
      //   //     {refresh_token},
      //   //     {headers: {_retry: true}},
      //   //   );
      //   //   //store access_token and data
      //   //   if (response.ok && response.status == 200) {
      //   //     api.setHeader(
      //   //       'Authorization',
      //   //       'Bearer ' + response.data.access_token,
      //   //     );
      //   //     originalRequest.headers.Authorization =
      //   //       'Bearer ' + response.data.access_token;
      //   //     await setLoginCredentials(JSON.stringify(response.data));
      //   //     resolve(api.axiosInstance(originalRequest));
      //   //   } else {
      //   //     resetLoginCredentials().then(e => {
      //   //       store.dispatch({
      //   //         type: '@action.login.changeAppState',
      //   //         payload: APP_STATE.PUBLIC,
      //   //       });
      //   //     });
      //   //     Toast.show('You need to login again.');
      //   //     return resolve(true);
      //   //   }
      //   // });
      // } else {
      //   return Promise.resolve(error);
      // }
    } else {
      return Promise.resolve(error);
    }
  }
);

export const setAuthorizationHeader = (access_token) =>
  api.setHeader("Authorization", "Bearer " + access_token);

export const setPropertyHeader = (id) => {
  console.log('header id', id)
  return api.setHeader("propertyid", id);
}

export const removePropertyHeader = () => {
  delete api.headers["propertyid"];
};

export const removeAuthorizationHeader = () => {
  delete api.headers["Authorization"];
};

export const setUserAgent = (info) => api.setHeader("device", info);

export { api as apiClient };

//for swr fetcher
export const getFetcher = (url, params, config) =>
  api
    .get(url, params, config)
    .then((response) => {
      response.setHeader("Access-Control-Allow-Origin", "*");
      return response.data;
    })
    .catch((er) => {
      throw er;
    });
