import React from 'react'
import { Flex, Text, VStack } from '@chakra-ui/react'
import { AiOutlineInbox } from "react-icons/ai";

export const EmptyPage = ({ message = '' }) => {
    return (
        <Flex pos='relative' justify={'center'} alignItems='center' width={'100%'} height={300}>
            <VStack>
                <AiOutlineInbox color='#718096' fontSize={100} />
                <Text fontSize={20} color='gray.500'>{message || 'No Data Available'}</Text>
            </VStack>
        </Flex>
    )
}