import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useSWR from 'swr'
import { URIS } from '.'
import { apis } from './apis'


export function useGetProperties(key = false, params = {}, revalidate) {
    const { data, error, mutate, isValidating } = useSWR(key ? URIS.GET_PROPERTIES+key : null, () => apis.getPropertiesApi({...params}), {
        revalidateOnFocus: false,
        revalidateIfStale:revalidate || false,
    })

    const toast = useToast()
    useEffect(() => {
        if (error) {
            toast({
                status: "error",
                title: "Properties not found",
            })
        }
    }, [data, error, toast])

    return {
        data: data?.data , error, loading : isValidating, mutate
    }
}